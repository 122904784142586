import React, { createContext, useContext, useState, useEffect } from "react";

export const ThemeContext = createContext();

export function ThemeProvider(props) {
  const [useDarkTheme, setUseDarkTheme] = useState(false);

  useEffect(() => {
    setUseDarkTheme(localStorage.getItem("useDarkTheme") === "true");

    if (
      localStorage.getItem("useDarkTheme") === null &&
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      setUseDarkTheme(true);
    }
  }, []);

  useEffect(() => {
    if (document && document.getElementsByTagName("html")[0]) {
      document.getElementsByTagName("html")[0].dataset.theme = useDarkTheme
        ? "dark"
        : "light";
    }

    localStorage.setItem("useDarkTheme", useDarkTheme);
  }, [useDarkTheme]);

  return (
    <ThemeContext.Provider
      value={{
        useDarkTheme,
        setUseDarkTheme,
      }}
      {...props}
    />
  );
}

export function useTheme() {
  return useContext(ThemeContext);
}
