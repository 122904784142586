/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";
import { ThemeProvider } from "./src/contexts/theme";

// eslint-disable-next-line react/prop-types
export function wrapRootElement({ element }) {
  return <ThemeProvider>{element}</ThemeProvider>;
}
